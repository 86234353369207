import { Link } from 'gatsby';
import React from 'react';

import parse, { domToReact } from 'html-react-parser';

import { BaseTags } from './TagsComponent';
import '../styles/post.css';
import '../styles/highlight.css';

const LinkToTweet = ({ url, children }) => (
  <a target="_blank" rel="noopener noreferrer" href={url}>
    {children}
  </a>
);

const parseHtml = html => {
  return parse(html, {
    replace: domNode => {
      if (
        domNode.type === 'tag' &&
        domNode.name === 'a' &&
        domNode.attribs['class'] === 'ltag__link__link' &&
        domNode.attribs['href'].includes('peaonunes/')
      ) {
        return (
          <a
            className="ltag__link"
            href={domNode.attribs['href'].replace('/peaonunes', '/blog')}
          >
            {domToReact(domNode.children)}
          </a>
        );
      }

      if (
        domNode.type === 'tag' &&
        domNode.name === 'a' &&
        domNode.attribs['class'] === 'ltag__link__link' &&
        domNode.attribs['href'].includes('/peaonunes')
      ) {
        return (
          <a
            className="ltag__link"
            href={domNode.attribs['href'].replace('/peaonunes', '/')}
          >
            {domToReact(domNode.children)}
          </a>
        );
      }


      if (
        domNode.type === 'tag' &&
        domNode.name === 'div' &&
        domNode.attribs['class'] === 'highlight__panel js-actions-panel'
      ) {
        console.log('hey')
        return <div />;
      }

      if (
        domNode.type === 'tag' &&
        domNode.name === 'div' &&
        domNode.attribs['class'] === 'ltag__twitter-tweet__video'
      ) {
        return <div />;
      }

      if (
        domNode.type === 'tag' &&
        domNode.name === 'blockquote' &&
        domNode.attribs['class'] === 'ltag__twitter-tweet'
      ) {
        return (
          <blockquote className="post__tweet">
            <LinkToTweet url={domNode.attribs['data-url']}>
              {domToReact(domNode.children)}
            </LinkToTweet>
          </blockquote>
        );
      }
    },
  });
};

const Post = ({ post }) => {
  const children = parseHtml(post.body_html);
  console.log(post.body_html);

  return (
    <React.Fragment>
      <div className="flex flex-col pt-10 pb-8">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
          <h1 className="text-4xl">
            <Link to={`/blog`}>
              <span
                className="hover:underline hover:bg-blue-300"
                role="img"
                aria-label="back to blog"
              >
                🔙
              </span>{' '}
            </Link>
            {post.title}
          </h1>
          <p className="text-xs hidden sm:block">
            {new Date(post.published_at).toLocaleDateString('en-GB')}
          </p>
        </div>
        <div className="sm:h-12 flex flex-col sm:flex-row items-center justify-between">
          <BaseTags
            className="flex w-full justify-between py-2 sm:max-w-xs text-sm"
            wrapperClass="pr-2"
            tagClass="p-2"
            tags={post.tags}
          />
          <div className="flex justify-between w-full sm:w-auto">
            <p className="text-xs sm:hidden">
              {new Date(post.published_at).toLocaleDateString('en-GB')}
            </p>
            <a
              className="text-xs text-primary-link hover:underline"
              href={post.canonical_url}
            >
              dev.to{' '}
              <span role="img" aria-label="link emoji">
                🔗
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="post pb-16">{children}</div>
      <div className="flex flex-col items-center align-middle pb-16 sm:flex-row sm:items">
        <a
          className="twitter-share-button"
          href={`https://twitter.com/intent/tweet?text=Just read ${post.title} by @peaonunes&hashtags=${post.tags.join(',')}`}
          data-size="large"
        >
          Tweet
        </a>
        <p className="pl-2 italic">
          Let me know what you think about this post on twitter!
        </p>
      </div>
    </React.Fragment>
  );
};

export { Post };
