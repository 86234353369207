import { graphql } from 'gatsby';
import React from 'react';

import { Post } from '../components/Post';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const PostTemplate = ({ data }) => {
  const post = data.allDevArticles.edges[0].node.article;

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.description}
        imageUrl={post.social_image}
      />
      <Post post={post} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($id: Int!) {
    allDevArticles(filter: { article: { id: { eq: $id } } }) {
      edges {
        node {
          article {
            id
            title
            description
            tags
            slug
            positive_reactions_count
            published_at
            body_html
            canonical_url
            social_image
          }
        }
      }
    }
  }
`;

export default PostTemplate;
